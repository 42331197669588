import { Fragment, useState, useEffect, useMemo } from "react";

import { VStack, Text } from "@chakra-ui/react";
import { Section, Page } from "@shared/models";

import PageItem from "./PageItem";
import SectionItem from "./SectionItem";
import { useNavigation } from "../../hooks/useNavigation";
import { useVisibilityFilters } from "../../hooks/useVisibilityFilters";
import { PageStatus } from "../../pages/sop-builder/SopBuilderPage";

interface SectionNavigationProps {
  sections: Section[];
  getSectionState: (section: Section) => {
    allChecked: boolean;
    isIndeterminate: boolean;
  };
  checkedItems: PageStatus;
  inProgressItems: PageStatus;
  handlePageClick: (section: Section, page: Page) => void;
  currentUrlSlug: {
    sectionUrlSlug: string;
    pageUrlSlug: string;
  };
}

const SectionNavigation: React.FC<SectionNavigationProps> = ({
  sections,
  getSectionState,
  checkedItems,
  inProgressItems,
  handlePageClick,
  currentUrlSlug,
}) => {
  const { sectionUrlSlug, pageUrlSlug } = currentUrlSlug;
  const { getLatestSectionAndPage } = useNavigation();
  const { shouldShowItem, getVisibleSections } = useVisibilityFilters();
  const { latestSection, latestPage } = getLatestSectionAndPage();

  const [collapsedSections, setCollapsedSections] = useState<Record<string, boolean>>(() => {
    return sections.reduce(
      (acc, section) => ({
        ...acc,
        [section.name]: section.urlSlug !== sectionUrlSlug
      }),
      {}
    );
  });

  const isPageDisabled = (section: Section, page: Page) => {
    if (!latestSection || !latestPage) return false;

    const latestSectionIndex = sections.findIndex(s => s.urlSlug === latestSection.urlSlug);
    const currentSectionIndex = sections.findIndex(s => s.urlSlug === section.urlSlug);

    if (currentSectionIndex > latestSectionIndex) return true;

    if (currentSectionIndex === latestSectionIndex) {
      const latestPageIndex = section.pages.findIndex(p => p.urlSlug === latestPage.urlSlug);
      const currentPageIndex = section.pages.findIndex(p => p.urlSlug === page.urlSlug);
      return currentPageIndex > latestPageIndex;
    }

    return false;
  };

  useEffect(() => {
    const currentSection = sections.find((s) => s.urlSlug === sectionUrlSlug)?.name;
    if (currentSection) {
      setCollapsedSections((prev) => {
        const newCollapsedState = { ...prev };
        // Set all sections to collapsed
        sections.forEach((section) => {
          newCollapsedState[section.name] = true;
        });
        // Uncollapse only the current section
        newCollapsedState[currentSection] = false;
        return newCollapsedState;
      });
    }
  }, [sectionUrlSlug, sections]);

  const handleReviewClick = () => {
    const reviewSection = { id: "complete", urlSlug: "complete", name: "Complete", pages: [] };
    const reviewPage = { id: "review", urlSlug: "review", name: "Review", questions: [], header: "Review" };
    handlePageClick(reviewSection, reviewPage);
  };

  const filteredSections = useMemo(() => getVisibleSections(sections), [sections, getVisibleSections]);

  return (
    <VStack alignItems="flex-start" w="400px" overflowY="auto" h="full">
      {filteredSections.map((section) => (
        <Fragment key={section.name}>
          <SectionItem
            section={section}
            isCollapsed={collapsedSections[section.name]}
            onToggle={() => setCollapsedSections((prev) => ({ ...prev, [section.name]: !prev[section.name] }))}
            isDisabled={latestSection && sections.indexOf(section) > sections.indexOf(latestSection)}
            {...getSectionState(section)}
          />
          {!collapsedSections[section.name] &&
            section.pages
              .filter(page => shouldShowItem(page))
              .map((page, index) => (
                <PageItem
                  key={`${section.name}-${page.name}-${index}`}
                  page={page}
                  isChecked={checkedItems[section.name]?.[page.name] === "completed"}
                  isIndeterminate={inProgressItems[section.name]?.[page.name] === "started"}
                  onClick={() => handlePageClick(section, page)}
                  isActive={section.urlSlug === sectionUrlSlug && page.urlSlug === pageUrlSlug}
                  isDisabled={isPageDisabled(section, page)}
                />
              ))}
        </Fragment>
      ))}

      <Text
        fontWeight={sectionUrlSlug === "complete" ? "bold" : "normal"}
        color={sectionUrlSlug === "complete" ? "brand.primary" : "inherit"}
        onClick={handleReviewClick}
        mt={5}
        fontSize="lg"
        cursor="pointer"
      >
        Review SOP
      </Text>
    </VStack>
  );
};

export default SectionNavigation;
