import { useState } from "react";

import ComponentPreview from "../ComponentPreview";
import Context from "../Context";

interface SOPContextTesterProps {
  render: boolean;
}

export const SOPContextTester = ({ render }: SOPContextTesterProps) => {
  const [currentContext, setCurrentContext] = useState<string | null>("Handwashing");

  const sopContextKeys = Object.keys(SOPContextData.SOPContext);
  console.log("SOP Context Keys:", sopContextKeys);

  const handleAffirmativeClick = () => {
    if (currentContext && sopContextKeys.indexOf(currentContext) + 1 < sopContextKeys.length) {
      setCurrentContext(sopContextKeys[sopContextKeys.indexOf(currentContext) + 1]);
    } else {
      setCurrentContext(sopContextKeys[0]);
    }
  };

  if (!render) return null;

  return (
    <ComponentPreview title="Context Component" render={render}>
      <Context
        answer={false}
        question={currentContext ? SOPContextData.SOPContext[currentContext].question || "" : ""}
        contentFile={currentContext ? SOPContextData.SOPContext[currentContext].contentFile || "" : ""}
        onAffirmativeClick={handleAffirmativeClick}
      />
    </ComponentPreview>
  );
};

export interface SOPContextType {
  title: string;
  question: string | null;
  contentFile: string;
}

export interface SOPContextData {
  SOPContext: {
    [key: string]: SOPContextType;
  };
}

export const SOPContextData: SOPContextData = {
  SOPContext: {
    Handwashing: {
      title: "Handwashing",
      question: "Do you understand the rules for proper handwashing?",
      contentFile: "/SOPContext/Handwashing.md"
    },
    PersonalHygiene: {
      title: "Personal Hygiene",
      question: "Do you understand the rules for proper personal hygiene?",
      contentFile: "/SOPContext/PersonalHygiene.md"
    },
    RTEFoods: {
      title: "Ready-to-eat (RTE) foods",
      question: "Do you understand the rules for ready-to-eat foods?",
      contentFile: "/SOPContext/RTEFoods.md"
    },
    EmployeeHealth: {
      title: "Employee Health",
      question: "Do you understand the rules for employee health?",
      contentFile: "/SOPContext/EmployeeHealth.md"
    },
    ApprovedFoodSources: {
      title: "Approved Food Sources",
      question: "Do you understand the rules for approved food sources?",
      contentFile: "/SOPContext/ApprovedFoodSources.md"
    }
  }
};
