import pdfMake from "pdfmake/build/pdfmake";
import { TDocumentDefinitions } from "pdfmake/interfaces";

// Note: Link in case we need to embed fonts directly
// https://stackoverflow.com/a/62480808/434

pdfMake.fonts = {
  // Default font should still be available
  Roboto: {
    normal: `${document.location.origin}/fonts/roboto/Roboto-Regular.ttf`,
    bold: `${document.location.origin}/fonts/roboto/Roboto-Medium.ttf`,
    italics: `${document.location.origin}/fonts/roboto/Roboto-Italic.ttf`,
    bolditalics: `${document.location.origin}/fonts/roboto/Roboto-MediumItalic.ttf`
  },
  Serif: {
    normal: `${document.location.origin}/fonts/serif/Tinos-Regular.ttf`,
    bold: `${document.location.origin}/fonts/serif/Tinos-Bold.ttf`,
    italics: `${document.location.origin}/fonts/serif/Tinos-Italic.ttf`,
    bolditalics: `${document.location.origin}/fonts/serif/Tinos-BoldItalic.ttf`
  },
  QwitcherGrypen: {
    normal: `${document.location.origin}/fonts/qwitcher-grypen/QwitcherGrypen-Regular.ttf`,
    bold: `${document.location.origin}/fonts/qwitcher-grypen/QwitcherGrypen-Bold.ttf`
  }
};

export async function buildPdfInBrowser(
  docDefinition: TDocumentDefinitions,
  fileName: string,
  previewInBrowser: boolean
) {
  console.log("Calling 'pdfMake.createPdf'");
  const pdfPrinter = pdfMake.createPdf(docDefinition);
  console.log("PDF created");

  if (previewInBrowser) {
    console.log("Open PDF in browser");
    pdfPrinter.open();
  } else {
    console.log("Downloading PDF in browser");
    pdfPrinter.download(fileName);
  }
}
