import { useEffect, useRef } from "react";

import { Textarea, TextareaProps } from "@chakra-ui/react";

interface AutoResizingTextareaProps extends TextareaProps {
  onSave?: () => void;
}

export const AutoResizingTextarea = ({ onSave, ...props }: AutoResizingTextareaProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      const length = textareaRef.current.value.length;
      textareaRef.current.setSelectionRange(length, length);
      adjustTextareaHeight();
    }
  }, [props.value]);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.onChange?.(e);
    adjustTextareaHeight();
  };

  return (
    <Textarea
      ref={textareaRef}
      onBlur={onSave}
      onChange={handleChange}
      autoFocus
      fontSize="md"
      w="full"
      whiteSpace="pre-wrap"
      minH="unset"
      overflow="hidden"
      resize="none"
      rows={1}
      py={2}
      lineHeight="shorter"
      variant="unstyled"
      sx={{
        "&::-webkit-scrollbar": {
          display: "none"
        }
      }}
      {...props}
    />
  );
};