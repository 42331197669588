import { useMemo } from "react";

import { Section, Page, Question, QuestionDependencyType } from "@shared/models";

import { useAppStore } from "../store";

export interface FilteredSection {
  id: string;
  name: string;
  urlSlug: string;
  pages: FilteredPage[];
}

export interface FilteredPage {
  id: string;
  name: string;
  urlSlug: string;
  questions: Question[];
}

export function useVisibilityFilters() {
  const { session } = useAppStore();

  const shouldShowItem = useMemo(
    () => (item: Section | Page) => {
      if (!item.dependencies) return true;

      const silentDependencies = item.dependencies.filter((dep) => dep.type === QuestionDependencyType.Silent);
      if (silentDependencies.length === 0) return true;

      return !silentDependencies.some((dep) => {
        const dependencyAnswer = session?.[dep.questionId]?.answer;
        return !dependencyAnswer;
      });
    },
    [session]
  );

  const getVisibleSections = useMemo(
    () => (sections: Section[]) => {
      return sections.filter((section) => shouldShowItem(section));
    },
    [shouldShowItem]
  );

  const getVisibleSectionsAndPages = useMemo(
    () => (sections: Section[]) => {
      const visibleSections = sections.filter(shouldShowItem);
      return visibleSections.map((section) => ({
        ...section,
        pages: section.pages.filter(shouldShowItem)
      }));
    },
    [shouldShowItem]
  );

  const getReportSections = useMemo(
    () => (sections: Section[]): FilteredSection[] => {
      return sections.reduce<FilteredSection[]>((filteredSections, section) => {
        const filteredPages = section.pages.reduce<FilteredPage[]>((pages, page) => {
          const filteredQuestions = page.questions.filter((question) => !question.excludeFromReport);
          if (filteredQuestions.length > 0) {
            pages.push({
              id: page.id,
              name: page.name,
              urlSlug: page.urlSlug,
              questions: filteredQuestions
            });
          }
          return pages;
        }, []);

        if (filteredPages.length > 0) {
          filteredSections.push({
            id: section.id,
            name: section.name,
            urlSlug: section.urlSlug,
            pages: filteredPages
          });
        }
        return filteredSections;
      }, []);
    },
    []
  );

  return {
    shouldShowItem,
    getVisibleSections,
    getVisibleSectionsAndPages,
    getReportSections
  };
}
