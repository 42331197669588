import { useQuery } from "@tanstack/react-query";

import { getUserInfo } from "../services/api-service";

export function useUserInfo() {
  return useQuery({
    queryKey: ["userAuthId"],
    queryFn: () => getUserInfo()
  });
}
