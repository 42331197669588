import { useState } from "react";

import ComponentPreview from "../ComponentPreview";
import CustomItemWithDefinedOptions from "../CustomItemWithDefinedOptions";

const customItems = ["Gas Range", "Ice Well"];

const definedOptionsList = ["Cooking", "Hot Holding", "Cold", "Cold Holding"];

const definedOptionsWithSelection: { option: string; selected: boolean }[] = definedOptionsList.map((option) => ({
  option: option,
  selected: false
}));

const customItemsWithDefinedOptionsList: { itemName: string; itemOptions: { option: string; selected: boolean }[] }[] =
  customItems.map((item) => ({
    itemName: item,
    itemOptions: definedOptionsWithSelection
  }));

export interface CustomItemsWithDefinedOptionsListTesterProps {
  render: boolean;
}

export const CustomItemsWithDefinedOptionsListTester = ({ render }: CustomItemsWithDefinedOptionsListTesterProps) => {
  const [items, setItems] = useState(customItemsWithDefinedOptionsList);

  const handleAddItem = (item: string) => {
    setItems([...items, { itemName: item, itemOptions: definedOptionsWithSelection }]);
  };

  if (!render) return null;

  return (
    <ComponentPreview title="Custom Items With Defined Options" render={render}>
      <CustomItemWithDefinedOptions
        multiSelect={false}
        maintainOrder={true}
        headers={{ itemType: "Equipment", optionsDescription: "Type" }}
        items={items}
        setItems={setItems}
        addItem={handleAddItem}
      />
    </ComponentPreview>
  );
};
