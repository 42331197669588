import { useEffect, useState } from "react";

import { Box, Spinner } from "@chakra-ui/react";
import { useClerk, useUser } from "@clerk/clerk-react";
import { Navigate } from "react-router-dom";

import { useUserInfo } from "../../hooks/useUserInfo";
import { useAppStore } from "../../store";

type PrivateRouteProps = {
  children: React.ReactNode;
  redirectTo?: string;
};

const RequireAuth = ({ children, redirectTo = "/login" }: PrivateRouteProps) => {
  const clerk = useClerk();
  const user = useUser();
  const [hasCheckedUserInfo, setHasCheckedUserInfo] = useState(false);
  const { userInfo, setUserInfo } = useAppStore();

  const { data: userInfoData } = useUserInfo();

  useEffect(() => {
    if (!clerk.loaded) {
      return;
    }
    if (!user.isSignedIn) {
      setHasCheckedUserInfo(true);
      return;
    }

    if (user.isSignedIn && userInfo) {
      setHasCheckedUserInfo(true);
      return;
    }

    if (user.isSignedIn && !userInfo && userInfoData) {
      const fetchUserInfo = () => {
        setHasCheckedUserInfo(true);
        setUserInfo(userInfoData);
      };
      fetchUserInfo();
    }
  }, [clerk.loaded, user.isSignedIn, userInfo, userInfoData]);

  if (!hasCheckedUserInfo) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="lg" />
      </Box>
    );
  }

  const isAuthenticated = user.isSignedIn;
  return isAuthenticated ? (children as React.ReactElement) : <Navigate to={redirectTo} />;
};

export default RequireAuth;
