import { HStack, Text, Icon } from "@chakra-ui/react";
import { Section } from "@shared/models";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";

import TransparentCheckbox, { CustomCheckboxIcon } from "./TrasparentCheckbox";

interface SectionItemProps {
  section: Section;
  isCollapsed: boolean;
  onToggle: () => void;
  allChecked: boolean;
  isIndeterminate: boolean;
  isDisabled?: boolean;
}

const SectionItem = ({ section, isCollapsed, onToggle, allChecked, isIndeterminate, isDisabled }: SectionItemProps) => (
  <HStack spacing={1}>
    {section.pages.length > 0 && (
      <Icon
        as={isCollapsed ? IoIosArrowForward : IoIosArrowDown}
        cursor={isDisabled ? "not-allowed" : "pointer"}
        onClick={isDisabled ? undefined : onToggle}
        w={5}
        h={5}
        color={isDisabled ? "gray.400" : "inherit"}
      />
    )}
    <TransparentCheckbox
      isChecked={allChecked}
      isIndeterminate={isDisabled ? false : isIndeterminate}
      onChange={isDisabled ? undefined : onToggle}
      cursor={isDisabled ? "not-allowed" : "pointer"}
      icon={<CustomCheckboxIcon isIndeterminate={isDisabled ? false : isIndeterminate} isChecked={isDisabled ? false : allChecked} />}
    >
      <Text fontSize="lg" fontWeight="bold" color={isDisabled ? "gray.400" : "inherit"}>
        {section.name}
      </Text>
    </TransparentCheckbox>
  </HStack>
);

export default SectionItem;
