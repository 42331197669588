import { Flex, Link, Text } from "@chakra-ui/react";

const Footer = () => {
  return (
    <Flex width="full" align="center" alignSelf="flex-end" justifyContent="center" bg={"#F9F8F8"} pb={2}>
      <Text fontSize="xs">
        Copyright &copy; {new Date().getFullYear()} by{" "}
        <Link href="https://www.avodah.dev" isExternal>
          Org
        </Link>
      </Text>
    </Flex>
  );
};

export default Footer;
