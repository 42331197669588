import { ReactNode } from "react";

import { Icon } from "@chakra-ui/icons";
import { IoIosGrid, IoIosBuild } from "react-icons/io";

import { AppConfig } from "../services/app-config-service";

export enum UserRole {
  User = "User",
  Admin = "Admin"
}

// const allRoles = [UserRole.User, UserRole.Admin];

export interface MenuLink {
  label: string;
  path: string;
  target?: string;
  icon?: ReactNode;
  userRoles: UserRole[];
}

export const TopMenuLinks: MenuLink[] = [
  // { label: "Team", path: "https://www/our-team", target: "_blank", userRoles: allRoles }
];

export let SideMenuLinks: MenuLink[] = [
  { label: "Home", path: "/", icon: <Icon as={IoIosGrid} />, userRoles: [UserRole.User] },
  { label: "SOP", path: "/sop-builder", icon: <Icon as={IoIosBuild} />, userRoles: [UserRole.User] }
];

// TODO: Remove this once we go live with profile-driven conversations
if (AppConfig.shouldHideWipFeatures) {
  SideMenuLinks = SideMenuLinks.filter((link) => link.label !== "Profile");
}
