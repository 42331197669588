import { Center } from "@chakra-ui/react";
import { UserProfile } from "@clerk/clerk-react";

// import SomeText from "./components/SomeText";

const AccountPage = () => {
  return (
    <Center px={[0, 6]} pt="8">
      <UserProfile routing="hash" />
    </Center>
  );
};

export default AccountPage;
