import { AnswerOptions, UserInfo, UserSopAnswer } from "@shared/models";
import axios from "axios";
// import { queryOptions, useMutation, useQuery } from "@tanstack/react-query";

// import { axiosClient } from "./httpclient-service";
// import { queryClient } from "../App";
import { AppConfig } from "./app-config-service";

export async function getUserInfo(): Promise<UserInfo> {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/user-info`);
  return data;
}

interface UpsertSopAnswerParams extends AnswerOptions {
  questionId: string;
}

export async function upsertSopAnswer({ questionId, answer, reportAnswer }: UpsertSopAnswerParams): Promise<void> {
  return await axios.post(`${AppConfig.apiUrl}/api/sop-answers`, { questionId, answer, reportAnswer });
}

export async function getSopAnswers(): Promise<UserSopAnswer[]> {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/sop-answers`);
  return data;
}

export async function deleteAllUserSopAnswers(): Promise<void> {
  return await axios.delete(`${AppConfig.apiUrl}/api/sop-answers`);
}

export async function upsertReportAnswers(
  reportAnswers: { questionId: string; reportAnswer: string | object }[]
): Promise<void> {
  return await axios.post(`${AppConfig.apiUrl}/api/sop-report-answers`, { reportAnswers });
}
