import { useMemo, useState, useEffect } from "react";

import { Question, QuestionType, QuestionDependencyType, QuestionDependency } from "@shared/models";

import { Session } from "../store";

interface DependencyState {
  unansweredDependencies: QuestionDependency[];
  unansweredRequiredDependencies: QuestionDependency[];
  hasUnansweredDependencies: boolean;
  hasUnansweredRequiredDependencies: boolean;
  dependencyAnswers: string[];
  inputValue: string;
  setInputValue: (value: string) => void;
}

export const useDependencyState = (
  question: Question,
  session: Session,
  onAnswerChange: (id: string, answer: string) => void
): DependencyState => {
  const { id, type, dependencies, options } = question;

  const dependencyState = useMemo(() => {
    const unansweredDeps =
      dependencies?.filter(
        (dep) => !session[dep.questionId]?.answer && dep.type !== QuestionDependencyType.NotRequired
      ) || [];

    const unansweredReqDeps = unansweredDeps.filter((dep) => dep.type === QuestionDependencyType.Required);

    return {
      unansweredDependencies: unansweredDeps,
      unansweredRequiredDependencies: unansweredReqDeps,
      hasUnansweredDependencies: unansweredDeps.length > 0,
      hasUnansweredRequiredDependencies: unansweredReqDeps.length > 0
    };
  }, [dependencies, session]);

  const dependencyAnswers = useMemo(
    () =>
      dependencies?.flatMap((dep) => (dep.questionId ? (session[dep.questionId]?.answer as string[]) || [] : [])) || [],
    [dependencies, session]
  );

  const [inputValue, setInputValue] = useState(() => (session[id]?.answer as string) ?? options?.join(" ") ?? "");

  useEffect(() => {
    if (type !== QuestionType.Textarea) return;

    const sessionAnswer = session[id]?.answer as string;
    const hasNoAnswer = !sessionAnswer;
    const hasDefaultOptions = options && options.length > 0;

    if (hasNoAnswer && !dependencyState.hasUnansweredDependencies && hasDefaultOptions) {
      const defaultValue = options[0];
      onAnswerChange(id, defaultValue);
      setInputValue(defaultValue);
    } else if (sessionAnswer && dependencyState.hasUnansweredDependencies) {
      onAnswerChange(id, "");
    } else if (sessionAnswer && !dependencyState.hasUnansweredDependencies) {
      setInputValue(sessionAnswer);
    }
  }, [type, session[id]?.answer, dependencyState.hasUnansweredDependencies, options, id]);

  return {
    ...dependencyState,
    dependencyAnswers,
    inputValue,
    setInputValue
  };
};
