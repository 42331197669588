import { Checkbox, CheckboxProps, Icon } from "@chakra-ui/react";
import { FaCircle, FaCircleDot } from "react-icons/fa6";
import { FaRegCircle } from "react-icons/fa6";

// Types
interface CustomCheckboxIconProps {
  isIndeterminate: boolean;
  isChecked: boolean;
}

export const CustomCheckboxIcon = ({ isIndeterminate, isChecked }: CustomCheckboxIconProps) => {
  if (isChecked) return <Icon as={FaCircle} color="green.500" />;
  if (isIndeterminate) return <Icon as={FaCircleDot} color="yellow.500" />;
  return <Icon as={FaRegCircle} color="gray.300" />;
};

const TransparentCheckbox = ({ children, ...props }: CheckboxProps) => (
  <Checkbox
    colorScheme="transparent"
    sx={{
      "> span:first-of-type": {
        border: "none",
        bg: "transparent"
      }
    }}
    {...props}
  >
    {children}
  </Checkbox>
);

export default TransparentCheckbox;
