import { Stack } from "@chakra-ui/react";

import Button from "~/components/Button";

interface YesNoSelectionProps {
  affirmativeText?: string;
  negativeText?: string;
  onAffirmativeClick: () => void;
  onNegativeClick: () => void;
  direction?: "row" | "column";
  selected?: boolean;
}

export const YesNoSelection = ({
  selected,
  affirmativeText = "Yes",
  negativeText = "No",
  onAffirmativeClick,
  onNegativeClick,
  direction = "row"
}: YesNoSelectionProps) => {
  return (
    <Stack direction={direction} spacing={4}>
      <Button
        colorScheme={selected === true ? "brand" : "gray"}
        color={selected === true ? "brand" : "gray"}
        onClick={onAffirmativeClick}
      >
        {affirmativeText}
      </Button>
      <Button
        colorScheme={selected === false ? "brand" : "gray"}
        color={selected === false ? "brand" : "gray"}
        onClick={onNegativeClick}
      >
        {negativeText}
      </Button>
    </Stack>
  );
};
