import { useCallback, useState, useEffect } from "react";

import { Section, UserSopAnswer } from "@shared/models";

import { useSopAnswers } from "./useSopAnswers";
import { useVisibilityFilters } from "./useVisibilityFilters";
import { workflow } from "../constants/workflow";
import { PageStatus } from "../pages/sop-builder/SopBuilderPage";
import { Session, useAppStore } from "../store";

export function usePageStatus() {
  const [pageStatus, setPageStatus] = useState<PageStatus>({});
  const { isLoading } = useSopAnswers();
  const { shouldShowItem } = useVisibilityFilters();
  const { currentUrlSlug, session } = useAppStore();

  const initializePageStatus = useCallback((sessionData: Session) => {
    const answers = Object.entries(sessionData).map(([questionId, data]) => ({
      questionId,
      answer: data.answer
    }));

    const newPageStatus: PageStatus = {};

    for (const section of workflow.sections) {
      newPageStatus[section.name] = {};
      for (const page of section.pages) {
        const requiredQuestions = page.questions?.filter((q) => q.required);
        const requiredQuestionIds = requiredQuestions?.map((q) => q.id);
        const answeredRequiredQuestions = answers?.filter(
          (answer) => requiredQuestionIds.includes(answer.questionId) && answer.answer !== undefined
        );

        if (requiredQuestionIds.length === 0) {
          newPageStatus[section.name][page.name] = "completed";
        } else if (!answeredRequiredQuestions?.length) {
          newPageStatus[section.name][page.name] = "not-started";
        } else if (answeredRequiredQuestions.length === requiredQuestionIds.length) {
          newPageStatus[section.name][page.name] = "completed";
        } else {
          newPageStatus[section.name][page.name] = "started";
        }
      }
    }

    return newPageStatus;
  }, []);

  useEffect(() => {
    if (session && !isLoading) {
      setPageStatus(initializePageStatus(session));
    }
  }, [session, initializePageStatus, isLoading]);

  const updatePageStatus = useCallback(
    (updatedAnswer: Omit<UserSopAnswer, "reportAnswer">): PageStatus => {
      const newPageStatus = { ...pageStatus };

      for (const section of workflow.sections) {
        for (const page of section.pages) {
          const requiredQuestions = page.questions.filter((q) => q.required);
          const requiredQuestionIds = requiredQuestions.map((q) => q.id);

          if (requiredQuestionIds?.includes(updatedAnswer.questionId)) {
            const answeredRequiredQuestions = requiredQuestionIds?.filter(
              (id) => id === updatedAnswer.questionId || pageStatus[section.name]?.[page.name] === "completed"
            );

            if (requiredQuestionIds.length === 0) {
              // If there are no required questions, mark as completed
              newPageStatus[section.name] = { ...newPageStatus[section.name], [page.name]: "completed" };
            } else if (answeredRequiredQuestions.length === 0) {
              newPageStatus[section.name] = { ...newPageStatus[section.name], [page.name]: "not-started" };
            } else if (answeredRequiredQuestions.length === requiredQuestionIds.length) {
              newPageStatus[section.name] = { ...newPageStatus[section.name], [page.name]: "completed" };
            } else {
              newPageStatus[section.name] = { ...newPageStatus[section.name], [page.name]: "started" };
            }
            setPageStatus(newPageStatus);
            return newPageStatus;
          }
        }
      }

      return newPageStatus;
    },
    [pageStatus]
  );

  const getSectionState = useCallback(
    (section: Section) => {
      const sectionStatus = pageStatus[section.name] || {};

      if (!shouldShowItem(section)) {
        return { allChecked: false, isIndeterminate: false };
      }

      const visiblePages = section.pages.filter(shouldShowItem);

      const allChecked = visiblePages.every((page) => sectionStatus[page.name] === "completed");
      const someChecked = visiblePages.some(
        (page) => sectionStatus[page.name] === "started" || sectionStatus[page.name] === "completed"
      );

      return { allChecked, isIndeterminate: someChecked && !allChecked };
    },
    [pageStatus, shouldShowItem]
  );

  const isNextPageReady = useCallback(() => {
    const { sectionUrlSlug, pageUrlSlug } = currentUrlSlug;
    const currentSection = workflow.sections.find((sec) => sec.urlSlug === sectionUrlSlug);
    const currentPage = currentSection?.pages.find((pg) => pg.urlSlug === pageUrlSlug);

    if (!currentSection || !currentPage) return false;

    const status = pageStatus[currentSection.name]?.[currentPage.name];
    return status === "completed" || status === "started";
  }, [currentUrlSlug, pageStatus]);

  return { updatePageStatus, pageStatus, isLoading, getSectionState, isNextPageReady };
}
