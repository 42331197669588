import { useEffect } from "react";

import {
  Button,
  Text,
  VStack,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Tooltip,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import ReviewSopPage from "./ReviewSopPage";
import DynamicQuestion from "../../components/DynamicQuestion";
import Welcome from "../../components/Welcome";
import { workflow } from "../../constants/workflow";
import { useNavigation } from "../../hooks/useNavigation";
import { usePageStatus } from "../../hooks/usePageStatus";
import { useSopAnswerMutation } from "../../hooks/useSopAnswerMutation";
import { useAppStore } from "../../store";

const SopPage: React.FC = () => {
  const navigate = useNavigate();
  const { currentUrlSlug, session } = useAppStore();
  const { sectionUrlSlug, pageUrlSlug } = currentUrlSlug;
  const { navigateToNext, getLatestSectionAndPage, navigateTo, isLoading: isLoadingNavigation } = useNavigation();
  const { isNextPageReady } = usePageStatus();

  const isIndex = !pageUrlSlug;
  const notReady = pageUrlSlug === "not-yet";

  const currentSection = workflow.sections.find((sec) => sec.urlSlug === sectionUrlSlug);
  const currentPage = currentSection?.pages.find((pg) => pg.urlSlug === pageUrlSlug);

  const { handleAnswerChange } = useSopAnswerMutation();

  const { latestSection, latestPage } = getLatestSectionAndPage();

  useEffect(() => {
    if (isIndex && !isLoadingNavigation && session && Object.keys(session).length > 0) {

      if (latestSection && latestPage) {
        navigateTo(latestSection.urlSlug, latestPage.urlSlug);
      }
    }
  }, [isIndex, latestSection, latestPage, session]);

  if (isIndex) {
    return (
      <VStack w="full" h="100%" alignItems="stretch" overflowY={"auto"} spacing={8} px={8} pb={8}>
        <Welcome />
        <Button minH={10} alignSelf={"flex-end"} colorScheme="brand" variant="outline" onClick={() => navigateTo("restaurant-information", "basic-info")}>
          Begin
        </Button>
      </VStack>
    );
  }

  if (notReady) {
    return (
      <VStack w="full" h="100%" alignItems="stretch" overflow={"hidden"}>
        <Text>This page isn't enabled yet - you go back</Text>
        <Button onClick={() => navigate(-1)} colorScheme="blue" w="fit-content">
          Go to Sop Builder
        </Button>
      </VStack>
    );
  }

  if (sectionUrlSlug === "complete" && pageUrlSlug === "review") {
    return <ReviewSopPage />;
  }

  if (!currentSection || !currentPage) {
    return (
      <VStack w="full" h="100%" alignItems="stretch" overflow={"hidden"}>
        <Text>Section or Page not found.</Text>
      </VStack>
    );
  }

  const onAnswerChange = (questionId: string, answer: string | boolean | object) => {
    const question = currentPage.questions.find((q) => q.id === questionId);
    if (!question) return;
    handleAnswerChange(questionId, answer, question);
  };

  return (
    <Card w="full" overflow="hidden" position="relative" minH="60vh">
      <CardHeader>
        <Breadcrumb>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{currentSection.name}</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{currentPage.name}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </CardHeader>

      <CardBody overflowY="auto">
        <Container maxWidth="container.lg" pt={5} pb={5} h="auto" overflowY="auto">
          <VStack alignItems="flex-start" justifyContent="flex-start" w="full" h="100%" gap={12}>
            {currentPage.questions.map((question) => (
              <DynamicQuestion
                key={question.id}
                question={question}
                handleNextClick={navigateToNext}
                session={session}
                onAnswerChange={onAnswerChange}
              />
            ))}
          </VStack>
        </Container>
      </CardBody>


      <CardFooter justifyContent="flex-end">
        <Tooltip
          hasArrow
          label="Complete all required questions to continue"
          isDisabled={isNextPageReady()}
          placement="left"
          textAlign="center"
          maxW="500px"
        >
          <Button
            w="fit-content"
            colorScheme="brand"
            variant="outline"
            onClick={() => navigateToNext()}
            isDisabled={!isNextPageReady()}
            title={!isNextPageReady() ? "Complete all questions to continue" : undefined}
          >
            Continue
          </Button>
        </Tooltip>
      </CardFooter>
    </Card>
  );
};

export default SopPage;
