import { useState, useCallback } from "react";

import { Section, Page } from "@shared/models";
import { useNavigate } from "react-router-dom";

import { useVisibilityFilters } from "../hooks/useVisibilityFilters";
import { sopGuide } from "../services/sop-builder-service";
import { useAppStore } from "../store";

interface NavigationState {
  currentSection: Section | null;
  currentPage: Page | null;
}

export function useNavigation() {
  const navigate = useNavigate();
  const { currentUrlSlug, setCurrentUrlSlug, session } = useAppStore();
  const [navigationState, setNavigationState] = useState<NavigationState>({
    currentSection: null,
    currentPage: null
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const updateNavigationState = useCallback(() => {
    setIsLoading(true);
    const { sectionUrlSlug, pageUrlSlug } = currentUrlSlug;
    const currentSection = sopGuide.sections.find((sec) => sec.urlSlug === sectionUrlSlug);
    const currentPage = currentSection?.pages.find((pg) => pg.urlSlug === pageUrlSlug);

    setNavigationState({ currentSection: currentSection as Section, currentPage: currentPage as Page });
    setIsLoading(false);
  }, [currentUrlSlug]);

  const { getVisibleSectionsAndPages } = useVisibilityFilters();

  const navigateTo = useCallback(
    (sectionUrlSlug: string, pageUrlSlug: string) => {
      setIsLoading(true);
      setCurrentUrlSlug({ sectionUrlSlug, pageUrlSlug });
      navigate(`/sop-builder/${sectionUrlSlug}/${pageUrlSlug}`);
      updateNavigationState();
      setIsLoading(false);
    },
    [navigate, setCurrentUrlSlug, updateNavigationState]
  );

  const navigateToNext = useCallback(() => {
    setIsLoading(true);
    const { sectionUrlSlug, pageUrlSlug } = currentUrlSlug;
    const visibleSections = getVisibleSectionsAndPages(sopGuide.sections);

    const currentSectionIndex = visibleSections.findIndex((sec) => sec.urlSlug === sectionUrlSlug);
    const currentSection = visibleSections[currentSectionIndex];

    if (!currentSection) {
      setIsLoading(false);
      return;
    }

    const currentPageIndex = currentSection.pages.findIndex((pg) => pg.urlSlug === pageUrlSlug);

    let nextSection, nextPage;

    if (currentPageIndex < currentSection.pages.length - 1) {
      // Next page in current section
      nextSection = currentSection;
      nextPage = currentSection.pages[currentPageIndex + 1];
    } else if (currentSectionIndex < visibleSections.length - 1) {
      // First page of next section
      nextSection = visibleSections[currentSectionIndex + 1];
      nextPage = nextSection.pages[0];
    } else {
      // All sections complete, go to review
      nextSection = {
        id: "complete",
        urlSlug: "complete",
        name: "Complete",
        pages: []
      };
      nextPage = {
        id: "review",
        urlSlug: "review",
        name: "Review",
        questions: [],
        header: "Review"
      };
    }

    navigateTo(nextSection.urlSlug, nextPage.urlSlug);
    setIsLoading(false);
  }, [currentUrlSlug, navigateTo, session]);

  const getLatestSectionAndPage = useCallback(() => {
    let latestSection = null;
    let latestPage = null;

    const shouldShowItem = (item: Section | Page) => {
      if (!item.dependencies) return true;

      const silentDependencies = item.dependencies.filter((dep) => dep.type === "silent");
      if (silentDependencies.length === 0) return true;

      return !silentDependencies.some((dep) => {
        const dependencyAnswer = session?.[dep.questionId]?.answer;
        return !dependencyAnswer;
      });
    };

    // First filter sections
    const visibleSections = sopGuide.sections.filter(shouldShowItem);

    for (const section of visibleSections) {
      // Then filter pages within each visible section
      const visiblePages = section.pages.filter(shouldShowItem);

      for (const page of visiblePages) {
        if (page.questions.every((question) => session[question.id]?.answer === undefined)) {
          latestSection = section;
          latestPage = page;
          return { latestSection, latestPage };
        }
      }
    }

    // If all pages in all sections have been answered, return the review page
    const reviewSection = { id: "complete", urlSlug: "complete", name: "Complete", pages: [] };
    const reviewPage = { id: "review", urlSlug: "review", name: "Review", questions: [], header: "Review" };
    return { latestSection: reviewSection, latestPage: reviewPage };
  }, [session]);

  return {
    currentSection: navigationState.currentSection,
    currentPage: navigationState.currentPage,
    navigateToNext,
    navigateTo,
    getLatestSectionAndPage,
    isLoading
  };
}
