import { Question } from "../../../../../shared/models";
import { Session } from "../../store";
interface Option {
  questionId: string;
  value: string;
}

interface SavedOption extends Option {
  xAxisOption?: Option;
  yAxisOption?: Option;
}

export const getYAxisOptions = (question: Question, session: Session): Option[] => {
  return (
    question.dependencies?.slice(0, -1).flatMap((dep) => {
      const selectedAnswers = (session[dep.questionId]?.answer as string[]) || [];
      return selectedAnswers.map((answer) => ({
        questionId: dep.questionId,
        value: answer
      }));
    }) || []
  );
};

export const getXAxisOptions = (question: Question, session: Session): Option[] => {
  const lastDependency = question.dependencies?.[question.dependencies.length - 1];
  const lastDependencyOptions = lastDependency ? (session[lastDependency.questionId]?.answer as string[]) : [];
  return (
    lastDependencyOptions?.map((option) => ({
      questionId: lastDependency?.questionId || "",
      value: option
    })) || []
  );
};

export const handleSingleSelect = (
  currentAnswer: SavedOption[],
  xAxisOption: Option,
  yAxisOption: Option,
  questionId: string
): SavedOption[] => {
  const existingIndex = currentAnswer.findIndex((option) => option.yAxisOption?.value === yAxisOption.value);

  if (existingIndex !== -1) {
    const existingOption = currentAnswer[existingIndex];
    if (existingOption.xAxisOption?.value === xAxisOption.value) {
      // Remove the option if it's the same as the existing one
      return [...currentAnswer.slice(0, existingIndex), ...currentAnswer.slice(existingIndex + 1)];
    } else {
      // Replace the existing option with the new one
      return [
        ...currentAnswer.slice(0, existingIndex),
        {
          questionId,
          value: xAxisOption.value,
          xAxisOption,
          yAxisOption
        },
        ...currentAnswer.slice(existingIndex + 1)
      ];
    }
  } else {
    // Add the new option if there wasn't an existing one
    return [
      ...currentAnswer,
      {
        questionId,
        value: xAxisOption.value,
        xAxisOption,
        yAxisOption
      }
    ];
  }
};

export const handleMultiSelect = (
  currentAnswer: SavedOption[],
  xAxisOption: Option,
  yAxisOption: Option,
  questionId: string
): SavedOption[] => {
  const existingOptionIndex = currentAnswer.findIndex(
    (option) => option.xAxisOption?.value === xAxisOption.value && option.yAxisOption?.value === yAxisOption.value
  );

  if (existingOptionIndex !== -1) {
    // Remove the option if it already exists
    return [...currentAnswer.slice(0, existingOptionIndex), ...currentAnswer.slice(existingOptionIndex + 1)];
  } else {
    // Add the new option
    return [
      ...currentAnswer,
      {
        questionId,
        value: xAxisOption.value,
        xAxisOption,
        yAxisOption
      }
    ];
  }
};
