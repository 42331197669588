import { Section, SOPGuide } from "../../../../shared/models";
import { workflow } from "../constants/workflow";

class SOPGuideBuilder {
  private sections: Array<Section> = [];

  constructor() {
    this.sections = [];
  }
  public addSection(section: Section): SOPGuide {
    return {
      sections: [...this.sections, section]
    };
  }
}

export const SOPBuilderService = new SOPGuideBuilder();

//TODO: I don't know if this needs to be exported, but I was getting rid of the linting errors
export const sopGuide: SOPGuide = {
  ...workflow
};
