import { Content, StyleDictionary, TDocumentDefinitions } from "pdfmake/interfaces";

import { buildPdfInBrowser } from "./pdfMakeService";
import { FilteredSection } from "../../pages/sop-builder/ReviewSopPage";
import { Session } from "../../store";
import { createAnswerStructure } from "../../utils/answerFormatters";

const styles: StyleDictionary = {
  header: {
    italics: true,
    fontSize: 11,
    alignment: "left",
    color: "#4A5568" // Chakra gray.700
  },
  heading: {
    fontSize: 24,
    bold: true,
    marginBottom: 20,
    color: "#2D3748" // Chakra gray.700
  },
  sectionHeader: {
    fontSize: 18,
    bold: true,
    marginTop: 20,
    marginBottom: 10,
    color: "#2D3748"
  },
  pageHeader: {
    fontSize: 16,
    bold: true,
    marginTop: 15,
    marginBottom: 8,
    color: "#4A5568" // Chakra gray.600
  },
  question: {
    fontSize: 13,
    bold: true,
    marginTop: 12,
    marginLeft: 12,
    color: "#2D3748"
  },
  footer: {
    fontSize: 10,
    color: "#212020"
  },
  answer: {
    fontSize: 12,
    color: "#4A5568",
    marginTop: 4,
    marginLeft: 12,
    lineHeight: 1.2
  },
  timestamp: {
    fontSize: 10,
    color: "#718096", // Chakra gray.500
    italics: true
  }
};

interface PdfOptions {
  includeTimestamp?: boolean;
  includeTimestampFooter?: boolean;
  customHeader?: string;
  customFooter?: string;
  establishmentName?: string;
}

function createPdfContent(sections: FilteredSection[], session: Session, options: PdfOptions = {}): Content[] {
  const content: Content[] = [];
  const timestamp = new Date().toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric"
  });

  // Add title
  content.push({
    text: options.customHeader || "State of Michigan SOP",
    style: "heading",
    alignment: "center"
  });

  if (options.includeTimestamp) {
    content.push({
      text: `Generated on ${timestamp}`,
      style: "timestamp",
      alignment: "right",
      marginBottom: 0
    });
  }

  sections.forEach((section) => {
    section.pages.forEach((page, pageIndex) => {
      page.questions.forEach((question, questionIndex) => {
        const questionData = session[question.id];
        const isFirstQuestionInSection = pageIndex === 0 && questionIndex === 0;

        let answerText = "-";

        if (questionData) {
          try {
            const formattedAnswer = createAnswerStructure(question, questionData.answer);

            if (typeof formattedAnswer === "object") {
              answerText = Object.entries(formattedAnswer)
                .map(([key, value]) => {
                  const formattedKey = key
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, (str) => str.toUpperCase())
                    .trim();

                  return `${formattedKey}: ${value}`;
                })
                .join("\n");
            } else {
              answerText = formattedAnswer || "-";
            }
          } catch (error) {
            console.error("Error formatting answer:", error);
            answerText = "Error displaying answer";
          }
        }

        const contentStack = [];

        if (isFirstQuestionInSection) {
          contentStack.push({
            text: section.name,
            style: "sectionHeader"
          });
        }

        // if (isFirstQuestionInPage) {
        //   contentStack.push({
        //     text: page.name,
        //     style: "pageHeader"
        //   });
        // }

        contentStack.push({
          text: question.prompt,
          style: "question"
        });

        contentStack.push({
          text: answerText,
          style: "answer"
        });

        content.push({
          stack: contentStack,
          unbreakable: true
        });
      });
    });
  });

  return content;
}

export async function generatePdf(
  sections: FilteredSection[],
  session: Session,
  fileName: string,
  options: PdfOptions = {},
  openInBrowser: boolean = false
): Promise<void> {
  try {
    const content = createPdfContent(sections, session, options);
    const docDefinition = createDocDefinition(content, options);
    const finalFileName = fileName.toLowerCase().endsWith(".pdf") ? fileName : `${fileName}.pdf`;
    // pdfMake.createPdf(docDefinition).download(finalFileName);
    buildPdfInBrowser(docDefinition, finalFileName, openInBrowser);
  } catch (error) {
    console.error("Error downloading PDF:", error);
    throw new Error("Failed to download PDF");
  }
}

function createDocDefinition(content: Content[], options: PdfOptions): TDocumentDefinitions {
  return {
    styles,
    pageSize: "LETTER",
    pageMargins: [40, 80, 40, 60],

    header: function () {
      return {
        stack: [
          {
            columns: [
              {
                text: [
                  {
                    text: options.establishmentName
                      ? options.establishmentName.length > 33
                        ? `${options.establishmentName.slice(0, 30)}...`
                        : options.establishmentName
                      : "",
                    style: "header"
                  }
                ],
                alignment: "left"
              },
              {
                text: options.customHeader || "State of Michigan SOP",
                alignment: "center",
                italics: true,
                fontSize: 11,
                style: "header"
              },
              {
                link: "https://uporontherockshospitality.com/",
                text: "Powered by Up or On the Rocks",
                alignment: "right",
                style: "header"
              }
            ]
          },
          {
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 5,
                x2: 530,
                y2: 5,
                lineWidth: 0.5,
                lineColor: "#b0afaf"
              }
            ]
          }
        ],
        margin: [40, 40, 40, 0]
      };
    },
    content,
    footer: function (currentPage, pageCount) {
      return {
        columns: [
          {
            link: "https://uporontherockshospitality.com/",
            text: "Powered by Up or On the Rocks (uporontherockshospitality.com)",
            alignment: "left",
            fontSize: 10,
            noWrap: true
          },
          {
            text: `Page ${currentPage} of ${pageCount}`,
            alignment: "right",
            fontSize: 10,
            width: 100
          }
        ],
        margin: [40, 20, 40, 0]
      };
    },
    info: {
      title: "State of Michigan SOP",
      author: "Up or On the Rocks",
      subject: "Standard Operating Procedure",
      keywords: "SOP, Michigan"
    }
  };
}
