// import { useEffect, useState } from "react";

import { Stack } from "@chakra-ui/react";

import Button from "./Button";

interface BooleanOptionsListProps {
  direction?: "row" | "column";
  maintainOrder?: boolean;
  selectedOptions?: string[];
  allOptions?: string[];
  onButtonClick: (item: string) => void;
}
/**
 * A list of boolean buttons that can be selected or unselected.
 *
 * @param direction - Layout direction, either "row" or "column". Default is "row".
 * @param maintainOrder - If true, selected option remains in its original position. Default is false.
 * @param selectedOptions - The currently selected option.
 * @param allOptions - Array of options to display.
 * @param onButtonClick - Callback function when an option is clicked.
 *
 * Renders a list of boolean buttons that can be selected or unselected.
 */

const BooleanOptionsList: React.FC<BooleanOptionsListProps> = ({
  direction = "row",
  selectedOptions,
  allOptions,
  onButtonClick,
  maintainOrder
}) => {
  return (
    <Stack direction={direction} spacing={3} w="full" h="fit-content" flexWrap="wrap" ml={1} mr={1}>
      {allOptions?.map((option, index) => (
        <Button
          key={index}
          colorScheme={selectedOptions?.includes(option) ? "blackAlpha" : "gray"}
          onClick={() => onButtonClick(option)}
          order={!maintainOrder && selectedOptions?.includes(option) ? -1 : 0}
          iconType={selectedOptions?.includes(option) ? "Remove" : "Add"}
          color={selectedOptions?.includes(option) ? "darkslategray" : "gray"}
          whiteSpace={direction === "row" ? "nowrap" : "normal"}
        >
          {option}
        </Button>
      ))}
    </Stack>
  );
};

export default BooleanOptionsList;
