import { AnswerOptions, QuestionType, UserInfo, UserSopAnswer } from "@shared/models";
import { create } from "zustand";

import { workflow } from "./constants/workflow";

export interface Message {
  messageid: string;
  accountid: string;
  sender: string;
  message: string;
  datetime: string;
}

export interface Conversation {
  conversationid: number;
  messages: Message[];
}

interface WorkflowUrlSlug {
  sectionUrlSlug: string;
  pageUrlSlug: string;
}

export interface SessionAnswer extends AnswerOptions {
  type: QuestionType;
}

export interface Session {
  [key: string]: SessionAnswer;
}

const findQuestionById = (questionId: string) => {
  for (const section of workflow.sections) {
    for (const page of section.pages) {
      const question = page.questions.find((q) => q.id === questionId);
      if (question) return question;
    }
  }
  return null;
};

interface AppState {
  userInfo?: UserInfo;
  filter: string;
  currentUrlSlug: WorkflowUrlSlug;
  session: Session;
  allConversations: Conversation[];
  filteredConversations: Conversation[];
  setUserInfo: (userInfo: UserInfo) => void;
  setFilter: (filter: string) => void;
  setSession: (session: Session) => void;
  setCurrentUrlSlug: (urlSlug: WorkflowUrlSlug) => void;
  setAllConversations: (all: Conversation[]) => void;
  setFilteredConversations: (filtered: Conversation[]) => void;
  updateSessionFromAnswers: (sopAnswers: UserSopAnswer[]) => void;
}

export const useAppStore = create<AppState>()((set, get) => ({
  userInfo: undefined,
  filter: "",
  currentUrlSlug: {
    sectionUrlSlug: "",
    pageUrlSlug: ""
  },
  session: {},
  allConversations: [],
  filteredConversations: [],
  setUserInfo: (userInfo) => set(() => ({ userInfo })),
  setCurrentUrlSlug: (urlSlug) => set(() => ({ currentUrlSlug: urlSlug })),
  setSession: (session) => set(() => ({ session })),
  setAllConversations: (all) => set(() => ({ allConversations: all })),
  setFilteredConversations: (filtered) => set(() => ({ filteredConversations: filtered })),
  setFilter: (filter) => set(() => ({ filter })),
  updateSessionFromAnswers: (sopAnswers) => {
    const currentSession = get().session;
    const newSession = sopAnswers.reduce<Record<string, SessionAnswer>>((acc, question) => {
      const completeQuestion = findQuestionById(question.questionId);
      acc[question.questionId] = {
        type: completeQuestion ? completeQuestion.type : QuestionType.Textarea,
        answer: question.answer,
        reportAnswer: question.reportAnswer
      };
      return acc;
    }, {});

    const hasChanged = Object.entries(newSession).some(([questionId, newAnswer]) => {
      const currentAnswer = currentSession?.[questionId];
      return (
        !currentAnswer ||
        currentAnswer.answer !== newAnswer.answer ||
        currentAnswer.reportAnswer !== newAnswer.reportAnswer ||
        currentAnswer.type !== newAnswer.type
      );
    });

    if (hasChanged) {
      set({ session: newSession });
    }
  }
}));
