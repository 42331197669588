import React from "react";

import { Heading, Text, TableContainer, Table, Thead, Tr, Th, Tbody, Td } from "@chakra-ui/react";

import { getYAxisOptions, getXAxisOptions, handleSingleSelect, handleMultiSelect } from "./helpers";
import { Question } from "../../../../../shared/models";
import { Session } from "../../store";
import BooleanOptionsList from "../BooleanOptionsList";

interface Option {
  questionId: string;
  value: string;
}

export interface TableMultiSelectSavedOption extends Option {
  xAxisOption?: Option;
  yAxisOption?: Option;
}

interface TableMultiselectProps {
  multiSelect?: boolean;
  question: Question;
  session: Session;
  onSelect: (value: TableMultiSelectSavedOption[]) => void;
}

const TableMultiselect: React.FC<TableMultiselectProps> = ({ multiSelect = true, question, session, onSelect }) => {
  const yAxisOptions = getYAxisOptions(question, session);
  const xAxisOptions = getXAxisOptions(question, session);

  const yAxisTitle = question.dependencies?.[0]?.label || "Y Axis";
  const xAxisTitle = question.dependencies?.[question.dependencies.length - 1]?.label || "X Axis";

  const handleOptionClick = (xAxisOption: Option, yAxisOption: Option) => {
    const currentAnswer = (session[question.id]?.answer as TableMultiSelectSavedOption[]) || [];
    let newAnswer: TableMultiSelectSavedOption[];

    if (!multiSelect) {
      newAnswer = handleSingleSelect(currentAnswer, xAxisOption, yAxisOption, question.id);
    } else {
      newAnswer = handleMultiSelect(currentAnswer, xAxisOption, yAxisOption, question.id);
    }

    onSelect(newAnswer);
  };

  const getSelectedOptions = (yAxisOption: Option) => {
    const answer = session[question.id]?.answer as TableMultiSelectSavedOption[] | undefined;
    return xAxisOptions
      .filter((xOption) =>
        answer?.some(
          (savedOption) =>
            savedOption.xAxisOption?.value === xOption.value && savedOption.yAxisOption?.value === yAxisOption.value
        )
      )
      .map((xOption) => xOption.value);
  };

  return (
    <TableContainer width="100%">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th width="25%" whiteSpace="normal">
              <Heading size="sm">{yAxisTitle}</Heading>
            </Th>
            <Th width="75%">
              <Heading size="sm">{xAxisTitle}</Heading>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {yAxisOptions.map((yOption, index) => (
            <Tr key={index}>
              <Td width="25%" whiteSpace="normal">
                <Text>{yOption.value}</Text>
              </Td>
              <Td width="75%">
                <BooleanOptionsList
                  maintainOrder={true}
                  selectedOptions={getSelectedOptions(yOption)}
                  allOptions={xAxisOptions.map((option) => option.value)}
                  onButtonClick={(selectedOption) => {
                    const xOption = xAxisOptions.find((option) => option.value === selectedOption);
                    if (xOption) handleOptionClick(xOption, yOption);
                  }}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default TableMultiselect;
