import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";

import { queryClient } from "../App";
import { deleteAllUserSopAnswers } from "../services/api-service";

export const useAdminDeleteAllSop = () => {
  const toast = useToast();

  return useMutation({
    mutationFn: deleteAllUserSopAnswers,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["sopAnswers"] });
      toast({
        title: "Success",
        description: "All SOP answers have been deleted.",
        status: "success",
        position: "top",
        duration: 3000
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to delete all SOP answers. Please try again.",
        status: "error",
        position: "top",
        duration: 3000
      });
      console.error("Error deleting all SOP answers:", error);
    }
  });
};
