import { BasicInfoFormData } from "../components/DynamicQuestion/CustomQuestions/BasicInfo";
import { FilteredSection } from "../pages/sop-builder/ReviewSopPage";
import { generatePdf } from "../services/pdf-service/pdfService";
import { Session } from "../store";

interface PdfGenerationOptions {
  includeTimestamp?: boolean;
  customFooter?: string;
  customHeader?: string;
}

export const usePdfGeneration = (filteredSections: FilteredSection[], session: Session) => {
  const generateSopPdf = async (preview: boolean, options: PdfGenerationOptions = {}) => {
    try {
      const fileName = `SOP_State_of_Michigan_${new Date().toLocaleDateString().replace(/\//g, "-")}`;
      const establishmentName =
        (session["restaurant-information-basic-info-details"]?.answer as BasicInfoFormData)?.establishmentName ?? "";

      await generatePdf(
        filteredSections,
        session,
        fileName,
        {
          includeTimestamp: true,
          customFooter: "Powered by Up or On The Rocks",
          establishmentName,
          ...options
        },
        preview
      );
    } catch (error) {
      console.error(error);
      throw error; // Re-throw to allow handling by the component
    }
  };

  return { generateSopPdf };
};
