import { useQuery } from "@tanstack/react-query";

import { getSopAnswers } from "../services/api-service";

export const useSopAnswers = () => {
  return useQuery({
    queryKey: ["sopAnswers"],
    queryFn: getSopAnswers
  });
};
