import { Button, HStack, Icon } from "@chakra-ui/react";
import { FaFilePdf } from "react-icons/fa6";
import { HiOutlineDocumentArrowDown } from "react-icons/hi2";

interface ShareButtonsProps {
  onDownload: () => void;
  onViewPdf: () => void;
  type: "primary" | "secondary";
}

export const ShareButtons = ({ onDownload, onViewPdf, type = "primary" }: ShareButtonsProps) => (
  <HStack w="full" justifyContent={type === "primary" ? "flex-end" : "flex-start"}>
    <Button
      size="sm"
      variant="solid"
      colorScheme="blackAlpha"
      bg="blackAlpha.100"
      color="brand.500"
      leftIcon={<Icon fontSize={20} as={FaFilePdf} />}
      onClick={onViewPdf}
    >
      View
    </Button>

    {/* <Button
      size="sm"
      variant="solid"
      colorScheme="blackAlpha"
      color="brand.500"
      bg="blackAlpha.100"
      leftIcon={<Icon as={FiSend} />}
      isDisabled
    >
      Email
    </Button> */}

    <Button
      size="sm"
      variant="solid"
      colorScheme="brand"
      bg={type === "primary" ? "brand.500" : "blackAlpha.100"}
      color={type === "primary" ? "white" : "brand.500"}
      leftIcon={<Icon fontSize={20} as={HiOutlineDocumentArrowDown} />}
      onClick={onDownload}
    >
      Download
    </Button>
  </HStack>
);
